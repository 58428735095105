import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
var Overlay = (function (_super) {
    __extends(Overlay, _super);
    function Overlay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop({ default: true })
    ], Overlay.prototype, "show", void 0);
    Overlay = __decorate([
        Component
    ], Overlay);
    return Overlay;
}(Vue));
export default Overlay;
